<template>
  <div id="register_page" class="login-page">
    <div class="login-bg">
      <div class="login-bg-shadow">
        <div class="login-bg-shadow-mask"></div>
      </div>
      <img src="../../assets/images/logo_login.png" />
    </div>

    <div class="login-main">

      <div class="login-container register-container" id="register_box" v-loading="isLoading">
        <div class="login-title">
          <!-- <img src="../../assets/images/icon/icon_user.png" alt="" /> -->
          <span class="title">注册</span>
        </div>
        <div class="login-content">
          <el-form :model="data" :rules="rules" ref="data" label-position="left">
            <el-form-item prop="tel">
              <el-input v-model="data.tel" type="text" placeholder="请输入手机号" @keyup.enter.native="submitForm()">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="data.password" placeholder="密码" type="password" show-password
                @keyup.enter.native="submitForm()"></el-input>
            </el-form-item>
            <el-form-item prop="code" class="sms-form-item">
              <el-input v-model="data.code" placeholder="验证码" @keyup.enter.native="submitForm()"></el-input>
              <span class="sms" @click="handleGetSms" :class="{ forbid: isSend }">
                {{ buttonTxt }}
                <span v-show="isSend && times != 0">({{ times }})s </span>
              </span>
            </el-form-item>
            <div class="agreement">
              <el-checkbox v-model="checked"> </el-checkbox>
              <span>本人确认已仔细阅读并充分理解
                <a href="#">《个人隐私保护政策》</a>与
                <a href="#">《网站使用条款》</a></span>
            </div>
            <el-button type="primary" :disabled="isSubmit" class="btn btn-success btn-block" @click="submitForm">注册
            </el-button>
            <div class="register-footer">
              已有账号，
              <span @click="$router.push('/login')">马上登录</span>
            </div>
          </el-form>
        </div>
        <div v-if="sysMsg" class="err-msg">{{ sysMsg }}</div>
      </div>

    </div>
  </div>
</template>

<script>
import { isPhone, isPassword } from "@/libs/checker.js";
import { validatePhone } from "@/libs/elementChecker.js";
import { hex_sha1 } from "@/libs/sha1.js";
export default {
  name: "login",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (!isPassword(value)) {
          callback(new Error("请输入密码，6-20个字符，由数字和字母组合"));
        } else {
          callback();
        }
      }
    };
    return {
      isSubmit: false,
      data: {
        tel: "",
        password: "",
        code: "",
      },
      rules: {
        tel: [
          {
            required: true,
            trigger: "blur",
            validator: validatePhone,
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            validator: validatePass,
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      sysMsg: "",
      SUCCESS_CODE: 0,
      isLoading: false,
      buttonTxt: "获取验证码",
      timer: null,
      times: "", // 计时
      isSend: false, // 是否发送了短信校验码
      checked: false,
    };
  },
  mounted() { },
  methods: {
    // 获取短信验证码
    handleGetSms() {
      let self = this;
      if (self.isSend) {
        return;
      }
      // 点击按钮时先校验手机号
      // if (!isPhone(self.data.tel)) {
      //   return this.$message.error(`请输入正确的手机号`);
      // }
      self.$refs.data.validateField("tel", function (error) {
        if (error) {
          return;
        } else {
          self.isSend = true;
          let params = {
            tel: self.data.tel,
            type: 4,
          };
          self.$http
            .post("/sys-user/getCheckCode", null, { params })
            .then((res) => {
              if (res.code == self.SUCCESS_CODE) {
                self.$message.success("短信发送成功！");
                self.countTime(60);
              } else {
                self.buttonTxt = "获取验证码";
                self.isSend = false;
                self.times = 0;
                self.$message.error(res.msg);
              }
            })
            .catch((err) => {
              self.buttonTxt = "获取验证码";
              self.isSend = false;
              self.times = 0;
              self.$message.error("获取失败，" + err);
            });
        }
      });
    },
    // 获取验证码，倒计时
    countTime(time) {
      let self = this;
      self.buttonTxt = "重新获取";
      self.times = Number(time);
      if (self.timer) {
        clearInterval(self.timer);
      }
      self.timer = setInterval(function () {
        self.times--;
        if (self.times == 0) {
          clearInterval(self.timer);
          self.buttonTxt = "获取验证码";
          self.isSend = false;
        }
      }, 1000);
    },
    // 账号密码登录
    goLogin() {
      if (!this.checked) {
        return this.$message.warning(
          "请确认已仔细阅读并充分理解 《个人隐私保护政策》与 《网站使用条款》"
        );
      }
      let params = {
        tel: this.data.tel,
        // password: this.data.password,
        password: hex_sha1(this.data.password),
        code: this.data.code,
      };
      this.isSubmit = true;
      this.isLoading = true;
      this.$http
        .post("/sys-user/register", params)
        .then((res) => {
          console.log(res);
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success(`注册成功,请登录!`);
            // 跳转到首页
            this.$router.push("/login");
          } else {
            this.$message.error(`注册失败，${res.msg}`);
          }
          this.isSubmit = false;
          this.isLoading = false;
        })
        .catch((err) => {
          this.$message.error(`注册失败，${err}`);
          this.isSubmit = false;
          this.isLoading = false;
        });
    },
    // 提交登录表单--登录
    submitForm() {
      this.$refs["data"].validate((valid) => {
        if (valid) {
          this.goLogin();
        } else {
          return false;
        }
      });
    },
  },
};
</script>
